<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.fu_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.file_upload_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.fu_code" label="Code" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.fu_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.file_name" label="File Name" outlined readonly append-outer-icon="mdi-download"></v-text-field>
                                <v-text-field v-model="form.process_status" label="Process Status" outlined readonly></v-text-field>
                                <v-textarea v-model="form.remarks" label="Remarks" outlined></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                </v-card>

                <v-toolbar  dark class="primary mt-5"><v-toolbar-title>Worksheet</v-toolbar-title>
                </v-toolbar>

                <v-card class="mt-1">
                    <v-list>
                        <template v-for="(worksheet,index) in this.form.worksheet_list"
                            >
                        <v-list-item two-line :key="'index' + index">
                            <v-list-item-content>
                                <v-list-item-title>{{ worksheet.name }}</v-list-item-title>
                                <v-list-item-subtitle>Process Status:{{ worksheet.process_status }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small icon dark class="primary" @click="openDetail(index)">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        </template>
                    </v-list>
                </v-card>                  

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApFileUploadService from "@/services/ApFileUploadService"
import ApParameterService from "@/services/ApParameterService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'File Upload',disabled: false,href:'/',},
              {text:'List',disabled: false,href:'/fileupload/list/member_registration',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            routeParam:[],
            form:{login_id:null,file_upload_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'member_registration',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['file_upload_id'] = this.$route.params.file_upload_id;
    this.routeParam['route_type'] = this.$route.params.route_type;
    //this.getParameter();
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.file_upload_id = this.routeParam.file_upload_id;
        this.form.route_type = this.routeParam.route_type;
        try{
            ApFileUploadService.view_file_upload(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View File Uploads',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        let request = {};
        request['login_id'] = "";
        request['file_upload_id'] = this.form.file_upload_id;
        request['route_type'] = this.routeParam.route_type;
        request['fu_code'] = this.form.fu_code;
        request['fu_name'] = this.form.fu_name;
        request['remarks'] = this.form.remarks;
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.update_file_upload(request).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update File Upload','Success.');
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update File Upload',e.response.data.error_message);
                })
                ;
            } catch(e) {
                console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    async getParameter(){
        try{
            let parameter = {login_id:"",param_type:"add-notification_template"}
            ApParameterService.get_parameter(parameter).then((res) => {
                this.notificationCategoryList = res.data.notificationCategoryList;
                this.notificationTypeList = res.data.notificationTypeList;
                console.log(res.data);
                
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },

    openDetail(wsIndex){
        
        this.$router.push({ name: 'fileupload-wsdetails', 
            params: { 
                file_upload_id: this.form.file_upload_id,
                route_type: this.form.route_type,
                ws_index: wsIndex,
                
            } 
        })

    },

  }
}
</script>